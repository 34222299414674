import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	Box,
} from '@mui/material';

export interface BannerDialogProps {
	open: boolean;
	onClose: () => void;
	onDismiss: () => void;
}

function BannerDialog(props: BannerDialogProps) {
	const { onClose, onDismiss, open } = props;

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>공지사항</DialogTitle>
			<DialogContent>
				<Box
					component="img"
					sx={{
						height: 233,
						width: '100%',
						maxHeight: { xs: 233, md: 167 },
						maxWidth: { xs: 350, md: 250 },
					}}
					alt="배너 이미지"
					src={process.env.PUBLIC_URL + 'center.webp'}
				/>
				<DialogContentText id="alert-dialog-description">
					여기에 원하는 텍스트를 삽입하세요. 예를 들어, 새로운 기능이 업데이트
					되었습니다!와 같은 공지사항을 넣을 수 있습니다.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDismiss}>다시 보지 않기</Button>
				<Button onClick={onClose} autoFocus>
					닫기
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default BannerDialog;
